define(function() {

  

  var youTubeVideo = function($containerElement) {
    this.videoElement = $containerElement.getElementsByTagName('iframe')[0];
    this.videoLinkOverlayElement = $containerElement.querySelectorAll('.video-link-overlay');
    this.videoLink = this.videoLinkOverlayElement[0] ? this.videoLinkOverlayElement[0].getAttribute('data-videoLink') : '';
    this.init();
  };

  youTubeVideo.prototype = {
    init: function() {
      this.setPlayers();
      if (this.videoLink) {
        this.listen();
      }
    },
    setPlayers: function() {
      var ytPlayer = function(playerId) {
        return new window.YT.Player(playerId);
      };

      this.video = ytPlayer(this.videoElement.id);
    },
    openLink: function() {
      window.location.href = this.videoLink;
    },
    listen: function() {
      var action = function() {
        this.openLink();
      }.bind(this);

      this.videoLinkOverlayElement[0].addEventListener('click', action);
    }
  };

  (function(window) {
    var $videoContainers;
    var loadApi;

    /* Youtube Looks for this on global */
    window.onYouTubePlayerAPIReady = function() {
      for (var i = 0; i < $videoContainers.length; i++) {
        new youTubeVideo($videoContainers[i]);
      }
    };

    loadApi = function() {
      var youTubejs = document.createElement('script');
      youTubejs.src = 'https://www.youtube.com/player_api';
      youTubejs.async = true;
      document.getElementsByTagName('head')[0].appendChild(youTubejs);
    };

    $videoContainers = document.querySelectorAll('.video-youtube-container');

    if ($videoContainers.length) {
      loadApi();
    }

  })(window);

});

